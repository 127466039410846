<template>
  <div>
    <van-sticky>
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="city"
          :options="cityList"
          @change="scrollIntoView(0)"
        />
        <van-dropdown-item
          v-model="county"
          :options="countyList"
          @change="scrollIntoView(1)"
        />
      </van-dropdown-menu>
    </van-sticky>
    <template v-for="(item, key) in list">
      <div :key="key" v-if="!city || city === key">
        <van-index-anchor v-if="city === ''" :index="key">
          {{ key }}
        </van-index-anchor>
        <template v-for="subItem in item">
          <van-cell
            :key="subItem.name"
            :title="subItem.name"
            :label="subItem.address"
            v-if="!county || county === subItem.county"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: require("./data.json"),
      city: "",
      county: "",
    };
  },
  computed: {
    indexList() {
      return Array.from(new Set(this.data.map((item) => item.city)));
    },
    cityList() {
      const list = this.indexList.map((item) => ({ text: item, value: item }));
      return [{ text: "全部城市", value: "" }, ...list];
    },
    countyList() {
      const all = { text: "全部区县", value: "" };
      if (this.city === "") return [all];
      const index = Array.from(new Set(this.list[this.city].map((item) => item.county)));
      return [all, ...index.map((item) => ({ text: item, value: item }))];
    },
    list() {
      const _list = {};
      this.data.map((item) => {
        if (!_list[item.city]) _list[item.city] = [];
        _list[item.city].push(item);
      });
      return _list;
    },
  },

  mounted() {
    document.title = "支持油站列表（安徽省）";
  },

  methods: {
    scrollIntoView(index) {
      if (!index) this.county = "";
      this.$nextTick(() => window.scrollTo(0, 0));
    },
  },
};
</script>
